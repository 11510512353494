// Background Colors
$BG_BLUE:#007AFF;
$BG_ORANGE:#FD7E39;
$BG_WHITE:#FFFFFF;
$BG_100:#FAFAFA;
$BG_200:#DDDDDD;
$BG_400:#BBBBBB;
$BG_600:#585858;
$BG_800:#252525;
$BG_BLACK:#000000;
$BG_RED:#F13131;
$BG_GREEN:#33C538;
$BG_BLUE_LIGHT:#F0F7FF;

// Text Colors
$TEXT_COLOR_BLUE:#007AFF;
$TEXT_COLOR_ORANGE:#FD7E39;
$TEXT_COLOR_WHITE:#FFFFFF;
$TEXT_COLOR_100:#FAFAFA;
$TEXT_COLOR_200:#DDDDDD;
$TEXT_COLOR_400:#BBBBBB;
$TEXT_COLOR_600:#585858;
$TEXT_COLOR_800:#252525;
$TEXT_COLOR_BLACK:#000000;
$TEXT_COLOR_RED:#F13131;
$TEXT_COLOR_GREEN:#33C538;
$TEXT_COLOR_BLUE_LIGHT:#F0F7FF;

// border-color

// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_15: 15px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_30: 30px;
$FONT_36: 36px;
$FONT_48: 48px;
$FONT_60: 60px;
$FONT_96: 96px;


// Font Family
$NatomPro: 'Natom Pro';
