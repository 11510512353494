@import "../../assets/scss/variables.scss";

.header {
  width: 100%;
  left: 0px;
  top: 0;
  position: fixed;
  z-index: 1111;
  box-shadow: none;
  background-color:$BG_100;
  .navbar {
    display: flex;
    align-items: center;
    width: 100%;
    float: left;
    justify-content: space-between;
    padding: 15px 50px;
    .navbar-brand {
      padding: 0px;
      margin: 0;
      float: left;
      text-align: center;
      width: 200px;
      &:focus {
        outline: none;
      }

      img {
        max-width: 100%;
      }
    }

    .profiledropdown {
      float: right;
      padding-right: 0px;
      padding-top: 0px;
      display: flex;
      .profilebtn{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .avtar {
        border-radius: 50%;
        padding: 0px;
        color: $TEXT_COLOR_BLACK;
        font-family: $NatomPro;
        font-size: $FONT_14;
        img {
          width: 56px;
          height: 56px;
          overflow: hidden;
          border-radius:50%;
          object-fit: cover;
          background-color: #E9E8ED;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -ms-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          -o-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        }
        .sortName {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #E9E8ED;
          font-family: $NatomPro;
          color:$TEXT_COLOR_BLACK;
          text-transform: uppercase;
          font-size: $FONT_14;
          line-height: 18px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .app_menu{
        display: flex;
        align-items: center;
        line-height: 18px;
        margin-right: 49px;
        text-align: left;
        font-family: $NatomPro;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px;
        &::after{
          content: "";
          width: 1px;
          height: 24px;
          background-color: $BG_200;
          left:24px;
          position: relative;
        }
        .txt {
          font-size: $FONT_14;
          color: $TEXT_COLOR_BLACK;
          line-height: 21px;
        }
        .icon-question-circle{
          font-size: $FONT_20;
          color: $TEXT_COLOR_BLACK;
          margin-right: 6px;
        }
      }
      .avtar-name {
        display: flex;
        align-items: center;
        line-height: 18px;
        margin-right: 0px;
        text-align: left;
        font-family: $NatomPro;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px;
        box-shadow: none !important;
        background-color: transparent !important;
        &::after{
          display: none;
        }
        p {
          font-size: $FONT_14;
          color:$TEXT_COLOR_BLACK;
          margin: 0;
        }
        .profile_details_name{
          display: flex;
          flex-direction: column;
          padding-left: 16px;
          padding-right: 16px;
          span {
            color: $TEXT_COLOR_BLACK;
            &.name{
              font-weight: 500;
              font-size: $FONT_16;
              line-height: 20px;
            }
            &.email{
              font-size: $FONT_14;
              line-height: 20px;
              font-weight: 400;
            }
          }
        }
        .icon-chevron-down-small{
          font-size: $FONT_24;
          color: $TEXT_COLOR_BLACK;
        }
      }
      .dropdown-menu{
        box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
        border-radius: 16px;
        border: none;
        width: 262px;
        top: 71px;
        right: 0;
        padding: 12px 16px;
        left: inherit;
        .dropdown-item{
          background-color: transparent;
          padding: 0px;
          margin: 14px 0;
          display: inline-flex;
          align-items: center;
          
          .cricle_box{
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $BG_BLUE_LIGHT;
            border-radius: 50%;
            margin-right: 24px;
            .icon-user-cricle{
              font-size: $FONT_24;
              color: $TEXT_COLOR_BLUE;
            }
            .icon-key{
              font-size: $FONT_24;
              color: $TEXT_COLOR_BLUE;
            }
            .icon-logout{
              font-size: $FONT_24;
              color: $TEXT_COLOR_RED;
            }
          }
          .menulink{
            font-size: $FONT_14;
            line-height: 21px;
            font-family: $NatomPro;
            font-weight: 400;
            padding-right: 16px;
            color: $TEXT_COLOR_BLACK;
          }
          .icon-chevron_big_right{
            font-size: $FONT_20;
            color: $TEXT_COLOR_BLACK;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:1400px){
  .header{
    .navbar{
      padding: 15px 24px;
    }
  } 
}

@media only screen and (max-width:575px){
  .header{
    .navbar{
      padding: 15px 16px;
      .navbar-brand {
        width: 155px;
      }
      .profiledropdown{
        .app_menu {
          margin-right: 40px;
          &::after{
            left: 19px;
          }
        }
        .avtar{
          img{
            width: 40px;
            height: 40px;
          }
        }
        .avtar-name{
          .profile_details_name{
            display: none;
          }
        }
      }
    } 
  } 
}