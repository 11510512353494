@import "../../../assets/scss/variables.scss";

.btn {
  &.custombtn {
    background: $BG_BLACK;
    width: 100% ;
    font-family: $NatomPro;
    font-size: $FONT_16 ;
    font-weight: 500;
    padding: 11px 24px;
    min-height: 48px;
    color: $TEXT_COLOR_WHITE;
    border:1px solid transparent !important;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.1);
    &.outline_btn{
      background-color: $BG_WHITE;
      border: 1px solid $BG_BLACK !important;
      color: $TEXT_COLOR_BLACK;
      &:not(:disabled) {
        background: $BG_WHITE;
      }
      &:not(:disabled):not(.disabled):active {
        background: $BG_WHITE;
        color: $TEXT_COLOR_WHITE;
        border: 1px solid $BG_BLACK;
      }
  
      &:not(.disabled):focus {
        box-shadow: none !important;
        background: $BG_WHITE;
        color: $TEXT_COLOR_BLACK;
      }
    }
    &:disabled {
      opacity: 1;
      cursor: no-drop;
      background: $BG_400;
      span{
        color: $TEXT_COLOR_WHITE;
      }
      &:focus {
      }
    }

    &:not(:disabled) {
      box-shadow: none;
      background: $BG_BLACK;
    }

    &:not(:disabled):not(.disabled):active {
      background: $BG_BLACK;
    }

    &:not(.disabled):focus {
      box-shadow: none !important;
      background: $BG_BLACK;
    }

    &.btn-loading {
      opacity: 1 !important;
      cursor: default;
    }
  }
}
