@import "./assets/scss/variables.scss";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield !important;
}

.pageMain {
  float: left;
  position: relative;
  max-width: 1445px;
  width: 100%;
  padding-left: 0px;
  .page {
    float: left;
    position: relative;
    width: 100%;
    background-color: $BG_RED;
  }
}