@import "./assets/scss/variables.scss";

body {
  margin: 0;
  font-family: $NatomPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $BG_100;
  overflow: hidden;
}
@font-face {
  font-family: 'Natom Pro';
  src: url('./assets/fonts/NatomPro-Regular.woff2') format('woff2'),
      url('./assets/fonts/NatomPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Natom Pro';
  src: url('./assets/fonts/NatomPro-Medium.woff2') format('woff2'),
      url('./assets/fonts/NatomPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'safer-walking';
  src:  url('./assets/fonts/safer-walking.eot?2x4vex');
  src:  url('./assets/fonts/safer-walking.eot?2x4vex#iefix') format('embedded-opentype'),
    url('./assets/fonts/safer-walking.ttf?2x4vex') format('truetype'),
    url('./assets/fonts/safer-walking.woff?2x4vex') format('woff'),
    url('./assets/fonts/safer-walking.svg?2x4vex#safer-walking') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'safer-walking' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trash-2-1:before {
  content: "\e900";
}

.icon-trash-2-2:before {
  // background-color: grey;
  opacity: 0.4;
  content: "\e900";
}

.icon-lock-on:before {
  content: "\e901";
}
.icon-location-2:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right-small:before {
  content: "\e904";
}
.icon-at-email:before {
  content: "\e905";
}
.icon-calendar-clock:before {
  content: "\e906";
}
.icon-calendar-edit:before {
  content: "\e907";
}
.icon-Check-circle:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e909";
}
.icon-chevron_big_right:before {
  content: "\e90a";
}
.icon-chevron-down-small:before {
  content: "\e90b";
}
.icon-chevron-down:before {
  content: "\e90c";
}
.icon-chevron-up:before {
  content: "\e90d";
}
.icon-cross:before {
  content: "\e90e";
}
.icon-edit:before {
  content: "\e90f";
}
.icon-eye-closed:before {
  content: "\e910";
}
.icon-eye-open:before {
  content: "\e911";
}
.icon-info-circle:before {
  content: "\e912";
}
.icon-key:before {
  content: "\e913";
}
.icon-location_pin:before {
  content: "\e914";
}
.icon-Location:before {
  content: "\e915";
}
.icon-logout:before {
  content: "\e916";
}
.icon-map:before {
  content: "\e917";
}
.icon-minus:before {
  content: "\e918";
}
.icon-pencil:before {
  content: "\e919";
}
.icon-phone-call:before {
  content: "\e91a";
}
.icon-phone:before {
  content: "\e91b";
}
.icon-plus:before {
  content: "\e91c";
}
.icon-question-circle:before {
  content: "\e91d";
}
.icon-Rectangle-394:before {
  content: "\e91e";
}
.icon-rotate-left:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e920";
}
.icon-settings:before {
  content: "\e921";
}
.icon-smartphone:before {
  content: "\e922";
}
.icon-user-cricle:before {
  content: "\e923";
}
.icon-user:before {
  content: "\e924";
}
.icon-Warning-circle:before {
  content: "\e925";
}
.icon-warning-round:before {
  content: "\e926";
}
.icon-waterdrop:before {
  content: "\e927";
}
.icon-bell:before {
  content: "\e928";
}
.icon-cart:before {
  content: "\e929";
}
.icon-clock:before {
  content: "\e92a";
}
.icon-creditcard:before {
  content: "\e92b";
}
.icon-circle:before {
  content: "\e92c";
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

* {
  box-sizing: border-box;
}

.modal-backdrop.show {
  opacity: .2;
}

