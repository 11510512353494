@import "../../assets/scss/variables.scss";

.modalPopup {
  .modal-dialog{
    width: 335px;
    margin: 0 auto;
    height: 100%;
    .modal-content {
      border: 0;
      overflow: hidden;
      -webkit-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
      -moz-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
      box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
      border-radius: 16px;
      padding: 16px 16px;
      .modal-header {
        position: relative;
        padding: 0px;
        border: 0;
        display: block;
        text-align: center;
        font-size: $FONT_18;
        line-height: 27px;
        color: $TEXT_COLOR_600;
        font-weight: 500;
      }
      .modal-body {
        padding: 16px 0;
        text-align: center;
        width: 100%;
        font-size: $FONT_14;
        line-height: 21px;
        color: $TEXT_COLOR_600;
      }
      .modal-footer{
        padding: 0px;
        border-top: none;
        .custombtn{
          margin: 0px;
          &.delete{
            background-color: $BG_RED;
          }
          &:nth-child(2){
            margin-top: 8px;
          }
        }
      }
    }
  }
}
