@import "../../../assets/scss/variables.scss";

.toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100% !important;
  z-index: 9999;
  text-align: center;
  flex-basis: inherit !important;
  margin: 0 auto !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 16px 0 0 0;
  display: none;
  opacity: 1 !important;

  .tostcenter {
    display: inline-block;
    min-width: inherit;
    max-width: 334px;
    padding: 16px 16px;
    text-align: left;
    margin: 0 auto;
    background-color: $BG_WHITE;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
    -moz-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
    box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
    .icon_row{
      width: 100%;
      float: left;
      text-align: center;
      .icon_toast{
        &.icon-check{
          font-size: $FONT_24;
          color: $TEXT_COLOR_BLUE;
        }
        &.icon-info-circle{
          font-size: $FONT_24;
          color: $TEXT_COLOR_ORANGE;
        }
        &.icon-Warning-circle{
          font-size: $FONT_24;
          color: $TEXT_COLOR_ORANGE;
        }
      }
    }
    p {
      width: 100%;
      float: left;
      margin: 0px;
      font-family: $NatomPro;
      font-size: $FONT_14;
      line-height: 21px;
      color: $TEXT_COLOR_600;
      font-weight: 400;
      position: relative;
      padding-top: 5px;
      text-align: center;
    }
  }
  &.show {
    display: block;
  }
}
